import CHOICES from "CHOICES";
import jwt_decode from "jwt-decode";
import pxd from "parse-xsd-duration";
import { toast } from "react-toastify";
import {
  ENV_MAPPER,
  APP_PERMISSION_MAPPER,
  PASSWORD_STRENGTH,
} from "utils/constants";
import moment from "moment";
export interface ProxyObjectType {}

const jwt = require("jsonwebtoken");

const emptyProxyObject: ProxyObjectType = new Proxy(
  {},
  {
    get: () => {
      return emptyProxyObject;
    },
  }
);

const isEmpty = (val: any) => {
  /*
    test results
    --------------
    [] true, empty array
    {} true, empty object
    null true
    undefined true
    "" true, empty string
    '' true, empty string
    0 false, number
    true false, boolean
    false false, boolean
    Date false
    function false
    */
  if (val === emptyProxyObject) return true;
  if (val === undefined) return true;

  if (
    typeof val == "function" ||
    typeof val == "number" ||
    typeof val == "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == "object") if (Object.keys(val).length === 0) return true;

  return false;
};

export const getValue = (val: any) => {
  return isEmpty(val) ? "-" : val;
};

export interface PasswordStrength {
  length: Boolean;
  numeric: Boolean;
  lowerCase: Boolean;
  upperCase: Boolean;
  specialCharacter: Boolean;
}

export const passwordValidator = (value: any) => {
  const result = {
    length: false,
    lowerCase: false,
    upperCase: false,
    specialCharacter: false,
    numeric: false,
  };
  if (!isEmpty(value)) {
    if (PASSWORD_STRENGTH.MIN_LENGTH <= value.length) {
      result.length = true;
    }
    if (value.match(/[_A-Z]/)) {
      result.upperCase = true;
    }
    if (value.match(/[_a-z]/)) {
      result.lowerCase = true;
    }
    if (value.match(/[_0-9]/)) {
      result.numeric = true;
    }
    if (value.match(/[_\W]/)) {
      result.specialCharacter = true;
    }
  }
  return result;
};

export const notify = (type: string, message: string) => {
  type === "success"
    ? toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
        theme: "colored",
      })
    : type === "warning"
    ? toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
        theme: "colored",
      })
    : toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
};

const setLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const getLocalStorage = (key: string) => {
  const value: any = window.localStorage.getItem(key);
  return !isEmpty(value) ? JSON.parse(value) : null;
};

const setSessionStorage = (key: string, value: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

const getSessionStorage = (key: string) => {
  const value: any = window.sessionStorage.getItem(key);
  return !isEmpty(value) ? JSON.parse(value) : null;
};

const clearLocalStorage = (key: Array<any>) => {
  key.forEach((item) => window.localStorage.removeItem(item));
};

const deepClone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

const convertDurationForUI = (duration: any) => {
  const converted = pxd(duration, true);
  let convertedFormat = "";
  if (converted?.days > 1) {
    convertedFormat = `${converted.days} <span>days</span> ${converted.hours} <span>hr</span> ${converted.minutes} <span>mins</span>`;
  } else if (converted?.days !== 0 && converted?.days === 1) {
    convertedFormat = `${converted.days} <span>day</span> ${converted.hours} <span>hr</span> ${converted.minutes} <span>mins</span>`;
  } else if (converted?.days === 0) {
    convertedFormat = `${
      converted?.hours > 0 ? converted?.hours + " <span>hr</span>" : ""
    } ${
      converted?.minutes > 0 ? converted?.minutes + " <span>mins</span>" : ""
    }`;
  }
  return convertedFormat;
};

const generateRedirectAppUrl = (host: string) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (
    baseUrl?.includes("localhost") ||
    baseUrl?.includes("127.0.0.1") ||
    baseUrl?.includes(".dev.")
  ) {
    return `https://${host}.dev.nuflights.com/`;
  }
  if (baseUrl?.includes(".qa.")) {
    return `https://${host}.qa.nuflights.com/`;
  }
  if (baseUrl?.includes(".staging.")) {
    return `https://${host}.staging.nuflights.com/`;
  }
  if (baseUrl?.includes(".intg.")) {
    return `https://${host}.intg.nuflights.com/`;
  }
  return `https://${host}.nuflights.com/`;
};

const getEnv = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (baseUrl?.includes("localhost") || baseUrl?.includes("127.0.0.1")) {
    return ENV_MAPPER.local;
  }
  if (baseUrl?.includes(".dev.")) {
    return ENV_MAPPER.development;
  }
  if (baseUrl?.includes(".qa.")) {
    return ENV_MAPPER.qa;
  }
  if (baseUrl?.includes(".staging.")) {
    return ENV_MAPPER.staging;
  }
  if (baseUrl?.includes(".intg.")) {
    return ENV_MAPPER.integration;
  }
  return ENV_MAPPER.production;
};

const verifyAuthToken = (authToken: string, appPermission: string) => {
  // TODO: add Interface for authToken
  let decodedToken: any, scope;
  try {
    decodedToken = jwt_decode(authToken);
    scope = decodedToken?.scope?.split(" ") || [];
  } catch (err) {
    console.error(err);
  }
  return Boolean(scope?.includes(APP_PERMISSION_MAPPER[appPermission]));
};

const convertCurrencyLocale = (currency: any, authToken: string) => {
  let decodedToken: any = jwt_decode(authToken);
  let locale = decodedToken["user_locale"] || "en-GB";
  const formatter = new Intl.NumberFormat(locale, { minimumFractionDigits: 2 });
  return formatter.format(currency);
};

const getCurrencyInShortForm = () => {
  const shortFormCurrencies: any = [];
  for (const [key, value] of Object.entries(CHOICES.CurrencyCode)) {
    if (Number(value) >= 0) {
      shortFormCurrencies.push({ label: key, value: value });
    }
  }
  return shortFormCurrencies;
};

const getTripType = () => {
  if (!isEmpty(CHOICES.ItenaryTypeConstant.CHOICE_LIST)) {
    const tripTypes: any = [];
    for (const list of CHOICES.ItenaryTypeConstant.CHOICE_LIST) {
      tripTypes.push({ label: list.label, value: list.value });
    }
    return tripTypes;
  } else {
    return null;
  }
};

const convertDateTime = (value: Date, formate: string) => {
  const newDate = new Date(value);
  const convert = moment(newDate).format(formate);
  return convert;
};

const showDateTime = (value: string, formate: string, time: string = "") => {
  let convert;
  if (time === "time") {
    convert = moment(value).utcOffset(value).format(formate);
  } else {
    convert = moment(value).utcOffset(value).format(formate);
  }
  return convert;
};

const refundAmount = (base: string, fixed: string, percentage: string) => {
  let result;

  const baseFare = base.toString();
  const fixedPenalty = fixed.toString();
  const percentagePenalty = percentage.toString();

  if (baseFare && fixedPenalty && percentagePenalty) {
    const calculation: any =
      Number(baseFare) > 0
        ? Number(baseFare) -
          (Number(fixedPenalty) +
            (Number(baseFare) * Number(percentagePenalty)) / 100)
        : 0;
    const temp = 0;
    result = calculation >= 0 ? calculation.toFixed(2) : temp.toFixed(2);
  } else {
    result = "-";
  }
  return result;
};
const totalAmount = (baseFare: string, tax: string, gstVat: string) => {
  let result;
  if (!isEmpty(baseFare) || !isEmpty(tax) || !isEmpty(gstVat)) {
    const calculation: any = Number(tax) + Number(baseFare) + Number(gstVat);
    result = calculation.toFixed(2);
  } else {
    result = "-";
  }
  return result;
};

const getAdultAndInfantSeats = (inventorySeat: any) => {
  let adultChild: number = 0,
    infant: number = 0,
    totalSeatCountAdult: number = 0,
    totalSeatCountInfant: number = 0,
    enabledInfant: boolean = false;

  inventorySeat?.map((seatObject: any) => {
    if (seatObject?.ptc == "INF") {
      enabledInfant = true;
      infant = infant + seatObject?.totalSeatCount - seatObject?.soldSeatCount;
      totalSeatCountInfant = totalSeatCountInfant + seatObject?.totalSeatCount;
    } else {
      adultChild =
        adultChild + seatObject?.totalSeatCount - seatObject?.soldSeatCount;
      totalSeatCountAdult = totalSeatCountAdult + seatObject?.totalSeatCount;
    }
  });

  return {
    adultChild: adultChild,
    infant: infant,
    enabledInfant: enabledInfant,
    totalSeatCountInfant: totalSeatCountInfant,
    totalSeatCountAdult: totalSeatCountAdult,
  };
};

const totalGstVatAmount = (
  adultGstVat: any,
  childGstVat: any,
  infantGstVat: any
) => {
  return (
    Number(!isEmpty(adultGstVat) ? adultGstVat : 0) +
    Number(!isEmpty(childGstVat) ? childGstVat : 0) +
    Number(!isEmpty(infantGstVat) ? infantGstVat : 0)
  );
};

const nodeEnv = process.env.NODE_ENV;
// TODO: Move to NODE_ENV approach
// const generateRedirectUrl = (host : string) =>
//     nodeEnv === "production"
//         ? `https://${host}.nuflights.com/`
//         : `https://${host}.${ENV_MAPPER[nodeEnv] || "dev"}.nuflights.com/`;

export {
  jwt,
  emptyProxyObject,
  nodeEnv,
  convertCurrencyLocale,
  getEnv,
  verifyAuthToken,
  isEmpty,
  setLocalStorage,
  getLocalStorage,
  setSessionStorage,
  getSessionStorage,
  clearLocalStorage,
  deepClone,
  generateRedirectAppUrl,
  getCurrencyInShortForm,
  convertDurationForUI,
  convertDateTime,
  showDateTime,
  refundAmount,
  totalAmount,
  getAdultAndInfantSeats,
  getTripType,
  totalGstVatAmount,
};
